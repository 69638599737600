exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preview-general-js": () => import("./../../../src/pages/preview/general.js" /* webpackChunkName: "component---src-pages-preview-general-js" */),
  "component---src-pages-preview-news-single-js": () => import("./../../../src/pages/preview/news-single.js" /* webpackChunkName: "component---src-pages-preview-news-single-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-templates-general-js": () => import("./../../../src/templates/general.js" /* webpackChunkName: "component---src-templates-general-js" */),
  "component---src-templates-general-wide-js": () => import("./../../../src/templates/general-wide.js" /* webpackChunkName: "component---src-templates-general-wide-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-news-single-js": () => import("./../../../src/templates/news-single.js" /* webpackChunkName: "component---src-templates-news-single-js" */),
  "component---src-templates-resource-listing-js": () => import("./../../../src/templates/resource-listing.js" /* webpackChunkName: "component---src-templates-resource-listing-js" */),
  "slice---src-components-core-footer-js": () => import("./../../../src/components/core/footer.js" /* webpackChunkName: "slice---src-components-core-footer-js" */),
  "slice---src-components-core-header-js": () => import("./../../../src/components/core/header.js" /* webpackChunkName: "slice---src-components-core-header-js" */)
}

